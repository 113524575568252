.registry {
  padding: 50px 0;

  h2 {
    text-align: center;
    font-size: 4rem;

    a {
      position: relative;
      top: 0px;
      text-decoration: none;
      background-color: #f4d6cc;
      color: #333;
      padding: 10px 20px 20px;
      border-radius: 24px;
      box-shadow: 0 5px 0 #fec5b2;

      &:active {
        position: relative;
        top: 5px;
        box-shadow: none !important;
      }
    }
  }
}
