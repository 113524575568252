.qa {
  padding: 50px 0;
  background-color: #f4d6cc;

  h2 {
    color: #333;
    text-align: center;
    font-size: 5rem;
  }

  p {
    font-size: 2rem;
    color: #333;
  }

  &__a {
    margin-bottom: 50px;
  }
}
