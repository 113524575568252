@import "../styles/mixin";

.menu-wrapper {
  max-width: 900px;
  margin: 0 auto;
}

.hamburgerMenu {
  display: none;

  @include respond-to("medium") {
    display: block;
    position: fixed;
    z-index: 100;
    top: 0;
  }
}

.menu {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  transition: 0.5s all;

  &.scrolling {
    background-color: #fff0f5;

    @include respond-to("medium") {
      li {
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);
      }
    }

    a {
      color: #000;
    }
  }

  @include respond-to("medium") {
    position: fixed;
    transform: translateY(-1000px);
    &.open {
      transform: translateY(0);
    }
  }

  ul,
  .open .hamburgerMenu + ul {
    display: block;
    font-size: 2.5rem;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    font-family: "Poiret One", cursive;
    text-align: center;

    @include respond-to("medium") {
      flex-direction: column;
    }

    li {
      width: 100%;

      @include respond-to("medium") {
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);

        &:hover {
          background-color: #f4d6cc;
        }
      }
    }

    a {
      display: block;
      color: #fff;
      text-decoration: none;
      width: 100%;
      padding: 10px 0;

      @include respond-to("medium") {
        &:hover {
          color: #333;
        }
      }
    }
  }
}
