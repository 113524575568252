.our-story {
  background-color: #f4d6cc;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10rem;

  @include respond-to("medium") {
    padding: 10rem 4rem;
  }

  h2,
  h3 {
    text-align: center;
    margin: 0 0 1.5rem;
    font-size: 5rem;
  }

  h2 {
    text-transform: capitalize;
  }

  img {
    width: 50px;
    margin: 0 auto 5rem;
  }

  p {
    font-size: 2rem;
    line-height: 1.6;
    text-align: center;
    color: #333;
  }
}
