.photos {
  padding: 50px 0;
  background-color: #fff;

  &__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;

    @include respond-to("medium") {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond-to("small") {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  img {
    object-fit: contain;
  }
}

h1 {
  text-align: center;
  font-size: 5rem;
}
