.rsvp {
  background-color: #f7eddd;
  padding-bottom: 5rem;

  &__title {
    font-weight: 400;
    text-align: center;
    font-size: 3rem;
    margin: 0;
    padding: 50px 0 30px;
  }

  &__label {
    font-family: "Poiret One", cursive;
    font-size: 1.8rem;
    span {
      //   color: #ee5862;
    }
  }

  &__input {
    display: block;
    width: 100%;
    border: 2px solid #f4d6cc;
    border-radius: 4px;
    padding: 1.5rem 0 1.5rem 1.5rem;
    margin: 1rem 0 1.5rem;
  }

  &__input-container {
    display: flex;
    margin: 1rem 0 1.5rem;

    @media (max-width: 495px) {
      flex-wrap: wrap;
    }
  }

  &__btn {
    width: 200px;
    padding: 1.5rem;
    position: relative;
    margin-bottom: 1.5rem;
    text-align: center;
    border: 2px solid #f4d6cc;
    border-radius: 4px;
    background-color: #fff;
    color: #000;
    margin-right: 1.5rem;
    transition: background-color 0.5s;
    font-family: "Poiret One", cursive;
    font-size: 1.8rem;

    &:hover {
      background-color: #fec5b2;
    }

    @media (max-width: 495px) {
      width: 100%;
      margin-right: 0;
    }

    &:first-child {
      @media (max-width: 495px) {
        margin-right: 0;
      }
    }

    &.active {
      background-color: #f4d6cc;
      color: #000;

      &:hover {
        background-color: #fec5b2;
      }
    }
  }

  &__input-radio {
    margin-bottom: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
  }

  &__fieldset {
    border: 0;
    padding: 0;
  }

  &__not-attending-message {
    margin-bottom: 2.5rem;
    font-size: 2rem;
    font-weight: 700;
    color: #055d57;
  }

  &__send {
    background-color: #f4d6cc;
    color: #333;
    border: 2px solid #f4d6cc;
    font-family: "Poiret One", cursive;
    font-size: 1.8rem;
    width: 200px;
    padding: 1.5rem;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.5s;

    @media (max-width: 495px) {
      width: 100%;
    }

    &:hover {
      background-color: #fec5b2;
    }
  }
}
