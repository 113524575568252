.wedding-details {
  padding: 5rem 0;
  text-align: center;

  h2 {
    font-size: 5rem;
    text-transform: capitalize;
  }

  h3 {
    font-size: 3.5rem;
  }

  h4 {
    font-size: 3rem;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  p {
    font-size: 2rem;
    font-family: "Poiret One", cursive;
  }

  img {
    width: 30px;
    margin: 0 auto;
  }

  &__container {
    border: 2px solid #f4d6cc;
    border-radius: 4px;
    width: fit-content;
    margin: 0 auto;
    padding: 20px 90px 40px;
  }

  &__time {
    position: relative;

    &:before {
      content: "";
      width: 25px;
      height: 1px;
      background-color: rgb(82, 80, 80);
      position: absolute;
      top: 10px;
      left: 145px;

      @include respond-to("medium") {
        width: 0;
      }
    }

    &:after {
      content: "";
      width: 25px;
      height: 1px;
      background-color: rgb(82, 80, 80);
      position: absolute;
      top: 10px;
      right: 145px;

      @include respond-to("medium") {
        width: 0;
      }
    }
  }

  &__location-name {
    margin-bottom: 0;
    font-size: 2.5rem;
  }

  &__address {
    margin-top: 10px;
    line-height: 1.4;

    span {
      display: block;
    }
  }

  a {
    color: rgb(21, 14, 89);
    text-decoration: none;
    font-size: 1.6rem;
    font-family: "Poiret One", cursive;
  }

  h5 {
    text-transform: uppercase;
    font-family: "Poiret One", cursive;
    font-size: 1.8rem;
  }
}
