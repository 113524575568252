@import "../styles/mixin";

header {
  position: relative;
  height: 100vh;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../assets/sdhero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

  @media (max-width: 480px) {
    background-position-x: 68%;
  }

  .heading {
    width: 100%;
    color: #fff;
    text-align: center;
    position: absolute;
    top: 40%;
    left: 62%;
    transform: translate(-50%, -50%);

    @media (max-width: 630px) {
      width: 80%;
      left: 50%;
    }

    p {
      font-size: 2.5rem;
      margin: 0;
    }

    h1 {
      font-size: 6rem;
      letter-spacing: 2px;
      margin-top: 1rem;
      text-transform: uppercase;

      @media (max-width: 900px) {
        font-size: 5rem;
      }
    }
  }
}
