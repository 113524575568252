html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  font-size: 10px;
}

body {
  margin: 0;
}

.wrapper {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 30px;
}

img {
  display: block;
  max-width: 100%;
}

h1,
h2,
h3,
h4 {
  font-family: "Poiret One", cursive;
}

p,
ul {
  font-family: "Special Elite", cursive;
}

h2 {
  font-size: 4rem;
}

@import "../Menu/menu";
@import "../Home/home";
@import "../Details/details";
@import "../Story/story";
@import "../Qa/qa";
@import "../Photos/photos";
@import "../Registry/registry";
@import "../Rsvp/rsvp";
@import "../Confirmation/confirmation";
