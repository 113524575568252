.confirmation {
  text-align: center;

  &__title {
    font-weight: 400;
    margin-bottom: 2rem;
    padding-top: 30%;
  }

  &__message {
    font-size: 1.5rem;
  }
}
