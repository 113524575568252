$breakpoints: (
  "small": 400px,
  "medium": 768px,
  "large": 1200px,
) !default;

@mixin respond-to($breakpoint) {
  @media (max-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}
